<template>
    <div>
      <!--线下支付-->
      <div class="center" style="background-color: white;padding:40px 0" v-show="payType=='LINE' && payStatus==0">
        <div style="font-size: 24px;width: 100%;text-align: center;margin-bottom: 45px">
          请您线下支付后，上传支付凭证或联系客服人员审核！
        </div>
        <div style="font-size: 14px;text-align: center;margin-bottom: 15px">
          支付金额：￥{{priceSum}}
        </div>
        <div style="font-size: 14px;text-align: center;margin-bottom: 30px">
          订单号：{{orderNo}}
        </div>
        <div style="width: 680px;margin:0 auto;background-color:#FAFAFA;height: 250px;padding: 50px 0">
          <div style="height: 30px;line-height: 30px;font-size: 14px;text-align: center">
            <span style="width: 200px;display: inline-block;text-align: right;margin-right: 20px;color: rgb(153, 153, 153);">收款人全称</span>
            <span style="width: 200px;display: inline-block;text-align: left">南京中高知识产权股份有限公司</span>
          </div>
          <div style="height: 30px;line-height: 30px;font-size: 14px;text-align: center">
            <span style="width: 200px;display: inline-block;text-align: right;margin-right: 20px;color: rgb(153, 153, 153);">收款人账号</span>
            <span style="width: 200px;display: inline-block;text-align: left">78929389217827316741</span>
          </div>
          <div style="height: 30px;line-height: 30px;font-size: 14px;text-align: center">
            <span style="width: 200px;display: inline-block;text-align: right;margin-right: 20px;color: rgb(153, 153, 153);">开户行名称</span>
            <span style="width: 200px;display: inline-block;text-align: left">中国人民银行</span>
          </div>
          <div style="height: 30px;line-height: 30px;font-size: 14px;text-align: center">
            <span style="width: 200px;display: inline-block;text-align: right;margin-right: 20px;color: rgb(153, 153, 153);">附加信息及用途</span>
            <span style="width: 200px;display: inline-block;text-align: left;vertical-align: top">订单号：{{orderNo}}</span>
          </div>
        </div>
        <div style="  border-width: 1px;
          border-color: rgb(255, 106, 0);
          border-style: solid;
          border-width: 1px;
          background-color: rgb(255, 255, 255);
          width: 178px;
          height: 38px;
          color:rgb(255, 106, 0) ;
          cursor: pointer;
          margin: 0 auto;
          text-align: center;
          line-height: 38px;
          margin-top: 30px;" @click="seeDetail()">查看订单</div>
      </div>

      <!--微信支付-->
      <div class="center" style="background-color: white;padding:40px 0" v-show="payType=='WECHAT' && payStatus==0">
        <div style="font-size: 24px;width: 100%;text-align: center;margin-bottom: 45px">
          请使用微信扫码完成支付！
        </div>
        <div id="wxQrcode" class="wxQrcodePay"></div>
      </div>


      <!--支付回调-->
      <div class="center" style="background-color: white;padding:40px 0" v-show="payStatus==1">
        <div style="font-size: 24px;width: 100%;text-align: center;margin-bottom: 45px">
          您的订单已完成支付，请等待后台运营人员审核！
        </div>
        <div style="font-size: 14px;text-align: center;margin-bottom: 15px">
          支付金额：￥{{priceSum}}
        </div>
        <div style="font-size: 14px;text-align: center;margin-bottom: 15px">
          订单号：{{orderNo}}
        </div>
        <div style="font-size: 14px;text-align: center;margin-bottom: 30px">
          支付方式：{{payTypeTxt}}
        </div>
        <div style="width: 410px;margin:0 auto">

          <div style="  border-width: 1px;
            display: inline-block;
            border-color: rgb(255, 106, 0);
            border-style: solid;
            border-width: 1px;
            background-color: rgb(255, 255, 255);
            width: 178px;
            height: 38px;
            color:rgb(255, 106, 0) ;
            cursor: pointer;
            margin: 0 auto;
            text-align: center;
            line-height: 38px;
            margin-top: 30px;" @click="seeDetail2()">查看订单</div>
          <div style="  border-width: 1px;margin-left: 10px;
            display: inline-block;
            border-color: rgb(255, 106, 0);
            border-style: solid;
            border-width: 1px;
            background-color: rgb(255, 106, 0);
            width: 178px;
            height: 38px;
            color:white ;
            cursor: pointer;

            text-align: center;
            line-height: 38px;
            margin-top: 30px;" @click="openZoosUrl">联系客服
          </div>
        </div>
      </div>

      <!--付款页面-->
      <div v-show="payStatus==-1" style="width: 100%;background-color: rgb(250,250,250)">
        <div style="overflow: hidden;height: auto;" class="center" >
          <div style="height:auto;background-color: white;margin-top: 10px;padding-bottom: 20px">
            <div style="height: 50px;line-height: 50px;padding-left: 10px;font-size: 14px;color: black;font-weight: bold;border-bottom: 1px solid rgba(220, 220, 220,.2);">买家信息</div>
            <div style="padding-left: 90px;padding-top: 40px;padding-right: 40px">
              <div style="margin-bottom: 20px" v-if="member.enterpriseName"><span>会员名称：{{member.enterpriseName}}</span></div>
              <div style="margin-bottom: 20px" v-if="member.name"><span>企业名称：{{member.name}}</span></div>
              <div style="margin-bottom: 20px;display: flex">
                <span style="display: inline-block;width: 80px">买家留言：</span>
                <Input v-model="remark" type="textarea" :autosize="{minRows: 1,maxRows:2}"></Input>
              </div>
            </div>
          </div>

          <div style="height: auto;margin-top: 10px;background-color: white">
            <div style="height: 50px;line-height: 50px;padding-left: 10px;font-size: 14px;color: black;font-weight: bold;border-bottom: 1px solid rgba(220, 220, 220,.2);">商品清单</div>
            <Table stripe :columns="goodColumns" :data="goods" style="padding: 20px 20px"></Table>
          </div>

          <div style="padding-bottom: 20px;margin-top: 10px;background-color: white">
            <div style="height: 50px;line-height: 50px;padding-left: 10px;font-size: 14px;color: black;font-weight: bold;border-bottom: 1px solid rgba(220, 220, 220,.2);">填写发票信息</div>
            <div style="
              padding-bottom: 20px;
              width: 1150px;
              margin: 0 auto;
              padding-top: 35px;
              background-color:rgba(220, 220, 220, .1);
              border-style: solid!important;
              border: none!important;
              border-color: rgb(225, 225, 225)!important;" class="div_center">
              <div id="button_style">
                <div v-for="(item,index) in faPiaoType" :key="index"  @click="faPiaochoose(index)" :class="{'div_button_select':faPiaoIndex==index,'div_button':faPiaoIndex!=index}">
                  {{item}}
                </div>

              </div>
              <div style="width: 50%;margin-top: 50px" v-show="faPiaoIndex==0">
                <Form :model="ordinaryInvoiceForm" :label-width="160" >
                  <FormItem label="发票抬头:">
                    <Input v-model="ordinaryInvoiceForm.invoiceHeader"></Input>
                  </FormItem>

                  <FormItem label="发票邮寄地址:">
                    <Input v-model="ordinaryInvoiceForm.invoiceMailingAddress"></Input>
                  </FormItem>

                  <FormItem label="发票收件人姓名:">
                    <Input v-model="ordinaryInvoiceForm.invoiceRecipientName"></Input>
                  </FormItem>

                  <FormItem label="发票收件人联系方式:">
                    <Input v-model="ordinaryInvoiceForm.invoiceRecipientPhone"></Input>
                  </FormItem>
                </Form>
              </div>
              <div style="margin-top: 50px;padding: 0 40px" v-show="faPiaoIndex==1">
                <Form :model="vatInvoiceForm" :label-width="160" >
                  <Row>
                    <Col span="12">
                      <FormItem label="企业名称:">
                        <Input v-model="vatInvoiceForm.invoiceHeader" placeholder="请输入企业名称"></Input>
                      </FormItem>
                    </Col>
                    <Col span="12">
                      <FormItem label="公司地址:">
                        <Input v-model="vatInvoiceForm.companyAddress" placeholder="请输入公司地址"></Input>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span="12">
                      <FormItem label="企业税号:">
                        <Input v-model="vatInvoiceForm.taxpayerNumber" placeholder="请输入企业税号"></Input>
                      </FormItem>
                    </Col>
                    <Col span="12">
                      <FormItem label="发票邮寄地址:">
                        <Input v-model="vatInvoiceForm.invoiceMailingAddress" placeholder="请输入发票邮寄地址"></Input>
                      </FormItem>
                    </Col>
                  </Row>

                  <Row>
                    <Col span="12">
                      <FormItem label="企业开户行:">
                        <Input v-model="vatInvoiceForm.businessBank" placeholder="请输入发票企业开户行"></Input>
                      </FormItem>
                    </Col>
                    <Col span="12">
                      <FormItem label="发票收件人姓名:">
                        <Input v-model="vatInvoiceForm.invoiceRecipientName" placeholder="请输入发票收件人姓名"></Input>
                      </FormItem>
                    </Col>
                  </Row>

                  <Row>
                    <Col span="12">
                      <FormItem label="企业银行开行账号:">
                        <Input v-model="vatInvoiceForm.businessBankNo" placeholder="请输入企业银行开行账号"></Input>
                      </FormItem>
                    </Col>
                    <Col span="12">
                      <FormItem label="发票收件人联系方式:">
                        <Input v-model="vatInvoiceForm.invoiceRecipientPhone" placeholder="请输入发票收件人联系方式"></Input>
                      </FormItem>
                    </Col>
                  </Row>

                  <Col span="12">
                    <FormItem label="公司电话:">
                      <Input v-model="vatInvoiceForm.businessPhone" placeholder="请输入公司电话"></Input>
                    </FormItem>
                  </Col>
                </Form>
              </div>
            </div>
          </div>

          <div style="height: 190px;background-color: white">
            <div style="height: 50px;line-height: 50px;padding-left: 10px;font-size: 14px;color: black;font-weight: bold;border-bottom: 1px solid rgba(220, 220, 220,.2);">选择支付方式</div>
            <div style="height: 140px;display: flex;align-items: center;padding-left: 50px">
              <div :class="{ pay_div: true, active: payType == 'WECHAT' }" @click="payType='WECHAT'"><img :src="weipay" alt=""><span>微信支付</span></div>
              <div :class="{ pay_div: true, active: payType == 'ALIPAY' }" @click="payType='ALIPAY'"><img :src="alipay" alt="" style="margin: 0 20px">支付宝支付</div>
              <div :class="{ pay_div: true, active: payType == 'LINE' }" @click="payType='LINE'"><img :src="money" alt="" style="margin: 0 20px">线下支付</div>
            </div>
          </div>
          <div style="height: 50px;text-align: right;font-size: 14px;line-height: 50px;background-color: white;margin-top: 10px;margin-bottom: 20px">
            订单总额 <span style="color: #FB7B3C;font-weight: bold">￥{{priceSum}}</span> <button style="margin-left: 10px;height: 50px;background-color:#FB7B3C;color: white;border: none;cursor: pointer;width: 100px" @click="goBuy">去支付</button>
          </div>
          <div v-html="payForm">

          </div>
        </div>
      </div>


      <div v-show="payStatus == 2" style="width: 1200px;margin: 0 auto">
        <div style="height: auto; border: 1px solid #dae2e5">
          <div
              style="
            height: 70px;
            padding-left: 20px;
            line-height: 70px;
            font-size: 20px;
       background-color:#F2F2F2!important;
            color: black;
          "
          >
            订单详情
          </div>
          <div style="padding-bottom: 30px" class="div_center2 pay_table">
          <span
              style="
              display: inline-block;
              font-size: 18px;
              margin-top: 20px;
              color: black;
              font-weight: bold;
              margin-bottom: 40px;
            "
          >基本信息</span
          >
            <div class="div_center2" style="border-bottom: none">
              <Row :gutter="16">
                <Col span="12">
                  <Row>
                    <Col span="24">
                      <div
                          style="height: 40px; line-height: 40px; font-size: 14px"
                      >
                      <span
                          style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >订单号</span
                      >：{{ detailItem.no }}
                      </div>
                      <div
                          style="height: 40px; line-height: 40px; font-size: 14px"
                      >
                      <span
                          style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >订单日期</span
                      >：{{ detailItem.updatedTime }}
                      </div>
                      <div
                          style="height: 40px; line-height: 40px; font-size: 14px"
                      >
                      <span
                          style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >订单状态</span
                      >：{{ detailItem.stateName }}
                      </div>
                      <div
                          v-if="detailItem.type === '支付宝'"
                          style="height: 40px; line-height: 40px; font-size: 14px"
                      >
                      <span
                          style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >支付类型</span
                      >：支付宝
                      </div>
                      <div
                          style="height: 40px; line-height: 40px; font-size: 14px"
                          v-if="detailItem.type === '微信'"
                      >
                      <span
                          style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >支付类型</span
                      >：微信
                      </div>
                      <div
                          style="height: 40px; line-height: 40px; font-size: 14px"
                          v-if="detailItem.type === '线下'"
                      >
                      <span
                          style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >支付类型</span
                      >：线下
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span="12">
                  <Row>
                    <Col span="24">
                      <div
                          style="height: 40px; line-height: 40px; font-size: 14px"
                      >
                      <span
                          style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >交易单号</span
                      >：{{ detailItem.tradeNo }}
                      </div>
                      <div
                          style="height: 40px; line-height: 40px; font-size: 14px"
                      >
                      <span
                          style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >会员名称</span
                      >：{{ detailItem.buyer }}
                      </div>
                      <div
                          style="height: 40px; line-height: 40px; font-size: 14px"
                      >
                      <span
                          style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >企业名称</span
                      >：{{ detailItem.buyerMember }}
                      </div>
                      <div
                          style="height: 40px; line-height: 40px; font-size: 14px"
                      >
                      <span
                          style="
                          text-align: right;
                          width: 60px;
                          display: inline-block;
                        "
                      >买家留言</span
                      >：{{ detailItem.remark || '无' }}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
          <div style="height: 300px" class="div_center2">
          <span
              style="
              display: inline-block;
              font-size: 18px;
              margin-top: 20px;
              color: black;
              font-weight: bold;
              margin-bottom: 40px;
            "
          >商品清单</span
          >

            <Table
                style="padding: 20px 20px"
                border
                :columns="detailColumn"
                :data="detailData"
                :disabled-hover="true"
            >
            </Table>
          </div>

          <div
              style="height: 200px; border-bottom: none !important"
              class="div_center2"
          >
          <span
              style="
              display: inline-block;
              font-size: 18px;
              margin-top: 20px;
              color: black;
              font-weight: bold;
              margin-bottom: 40px;
            "
          >发票信息</span
          >

            <div>
              <div
                  style="
                display: inline-block;
                margin-left: 50px;
                margin-right: 200px;
              "
              >
                发票抬头：<span>{{ detailItem.invoiceHeader }}</span>
              </div>
              <div style="display: inline-block">
                纳税人识别号：<span>{{ detailItem.taxpayerNumber }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import weipay from '@/assets/image/science/wepay.png'
  import alipay from '@/assets/image/science/alipay.png'
  import money from '@/assets/image/science/money.png'
  import {get, post} from "../../plugins/http/http";
  import baseUrl from "../../plugins/http/baseUrl";
  import QRCode from 'qrcodejs2';
    export default {
        name: "PatentGoodPay",
        data(){
          return {
            detailItem: {},
            detailData: [],
            detailColumn: [
              {
                title: "店铺",
                key: "shopName",
                align: "center",
                className: 'test-name'
              },
              {
                title: "商品名称",
                key: "goods",
                align: "center",
                className: 'test-name'
              },
              {
                title: "商品价格",
                key: "price",
                align: "center",
                className: 'test-name'
              },
              {
                title: "服务费",
                key: "serviceCharge",
                align: "center",
                className: 'test-name'
              },
              {
                title: "订单总额",
                key: "totalPrice",
                align: "center",
                className: 'test-name'
              },
            ],
            money: money,
            alipay: alipay,
            weipay: weipay,
            faPiaoIndex: 0,
            payStatus: -1,
            payType: "WECHAT",
            ordinaryInvoiceForm: {
              invoiceHeader: "",
              invoiceMailingAddress: "",
              invoiceRecipientName: "",
              invoiceRecipientPhone: ""
            },
            vatInvoiceForm: {
              businessBank: "",
              businessBankNo: "",
              businessPhone: "",
              companyAddress: "",
              invoiceHeader: "",
              invoiceMailingAddress: "",
              invoiceRecipientName: "",
              invoiceRecipientPhone: "",
              taxpayerNumber: ""
            },
            remark: '',
            faPiaoType: ["个人发票", '企业发票'],
            fwflChoice: 0,
            goodColumns: [
              {
                title: '店铺',
                key: 'shopName',
                align:'center',
                className: 'test-name'
              },
              {
                title: '商品名称',
                key: 'name'
                ,
                align:'center',
                className: 'test-name'
              },
              {
                title: '交易类型',
                key: 'type',
                align: 'center',
                className: 'test-name'
              },
              {
                title: '商品价格',
                key: 'price',
                align:'center',
                className: 'test-name'
              },
              {
                title: '服务费',
                key: 'fee',
                align:'center',
                className: 'test-name'
              }
            ],
            goods: [],
            member: {},
            shop: {},
            priceSum: 0,
            payForm: '',
            orderId: '',
            orderNo:"",
            checkOrderTimer: 0
          }
        },
        mounted() {
          if(!this.$route.query.payStatus){
            this.orderPre();
            //this.getGoodDetail();
          } else {
            this.payStatus = this.$route.query.payStatus;
            this.payType = this.$route.query.payType;
            this.orderId = this.$route.query.orderId;
            this.checkPayStatus(this.orderId);
          }
        },
        watch: {
          $route: {
            immediate: true, // 一旦监听到路由的变化立即执行
            handler(to, from) {

              let action = to.query.action || 0;
              if (JSON.stringify(to.query) !== '{}') {
                this.$emit('changeIndex', action);
              }
            }
          }
        },
        computed: {
          faPiao() {
            return this.faPiaoIndex ? this.vatInvoiceForm : this.ordinaryInvoiceForm;
          },
          payTypeTxt() {
            let result = '';
            switch(this.payType) {
              case "ALIPAY":
                result = '支付宝';
                break;
              case "WECHAT":
                result = '微信';
                break;
              case "LINE":
                result = '线下支付';
                break;
            }
            return result;
          }
        },
        destroyed() {
          clearInterval(this.checkOrderTimer);
        },
        methods:{
          seeDetail(){
            this.$router.push({
              name: "enterprise",
              query: {
                id: 3,
              },
              params: {
                pgId: "enterprise-management-center",
                orderSn:this.orderNo
              },
            });
          },
          seeDetail2() {
            this.id = this.$route.query.orderId || this.orderId;
            this.$router.push({
              path:'/enterprise/enterprise-management-center?id=3',
              query:{
                orderId:this.id
              }
            })
            // get(`${baseUrl}/v1/order/detail/${this.id}`).then((res) => {
            //   let item = res.result;
            //   this.detailItem = res.result;
            //   let detailData = {};
            //   detailData.shopName = item.shopName;
            //   detailData.goods = item.goods[0].goodsName;
            //   detailData.goodsClassify = item.goods[0].goodsClassify;
            //   detailData.price = "¥" + item.goods[0].price;
            //   detailData.serviceCharge = "¥" + item.goods[0].serviceCharge;
            //   detailData.totalPrice =
            //       "¥" + item.goods[0].price + item.goods[0].serviceCharge;
            //   this.detailData.push(detailData);
            //   // this.$router.push({
            //   //   path: "/enterprise/enterprise-management-center",
            //   //   query: {
            //   //     id: this.$route.query.id,
            //   //   },
            //   // });
            //   this.payStatus = 2;
          },
          openZoosUrl() {
            openZoosUrl('chatwin');
          },
          faPiaochoose(index) {
            this.faPiaoIndex = index
          },
          orderPre() {
            get(`${baseUrl}/v1/order/pre`, {
              goodsId: this.$route.query.goodId
            }).then((res) => {
                if(res.code === 0){
                  let goods = res.result.goods;
                  let stock = res.result.goodsStock;
                  this.goods = [{
                    shopName: goods.shopName,
                    name: goods.name,
                    type: goods.modeName,
                    price: stock.price,
                    fee: stock.servicePrice
                  }];
                  this.priceSum = stock.price + stock.servicePrice;
                  this.shop.id = goods.shopId;
                  if(res.result.member){
                    let member = res.result.member
                    this.member = {
                      enterpriseName: member.enterpriseName || '',
                      name: member.name
                    }
                  }

              }
            })
          },
          getGoodDetail(){
            get(`${baseUrl}/v1/goods/one`, {
              goodsId: this.$route.query.goodId
            }).then((res) => {
                if(res.code === 0){
                  this.shop = res.result.shop;
                }
            })
          },
          goBuy() {
            let fapiao = this.faPiao;
            post(`${baseUrl}/v1/order/makeOrder`,{
              businessBank: fapiao.businessBank,
              businessBankNo: fapiao.businessBankNo,
              businessPhone: fapiao.businessPhone,
              companyAddress: fapiao.companyAddress,
              invoiceHeader: fapiao.invoiceHeader,
              invoiceMailingAddress: fapiao.invoiceMailingAddress,
              invoiceRecipientName: fapiao.invoiceRecipientName,
              invoiceRecipientPhone: fapiao.invoiceRecipientPhone,
              taxpayerNumber: fapiao.taxpayerNumber,
              goodsId: this.$route.query.goodId,
              num: 1,
              remark: this.remark,
              shopId: this.shop.id,
              type: this.payType
            }).then((res) => {
              if(res.code ===0) {
                this.orderId = res.result.id;
                this.orderNo = res.result.sonOrders[0].no;
                if(this.payType === "LINE") {
                  this.payStatus = 0;
                } else {
                  this.pay(res.result.sonOrders[0].id)
                }
              } else {
                this.$Message.info(res.message);
              }
            })
          },
          pay(orderId) {
            get(`${baseUrl}/v1/pay/${orderId}`, {
              notifyUrl: '',
              returnUrl: location.href + `&payStatus=1&orderId=${orderId}&payType=${this.payType}`
            }).then((res) => {
              if(res.code === 0){
                //this.shop = res.result.shop;
                if(this.payType === 'ALIPAY') {
                  this.payForm = res.result.form;
                  setTimeout(()=>{
                    document.forms[2].submit()
                  })
                } else {
                  this.payStatus = 0;
                  this.qrcode(res.result.params.code_url);
                  this.loopCheckOrder(orderId);
                }
              } else {
                this.$Message.info(res.message);
              }
            })
          },
          checkPayStatus(id) {
            get(`${baseUrl}/v1/order/detail/${id}`).then((res) => {
              if(res.code === 0){
                if (res.result.state === 'EXAMINING') {
                  this.payStatus = 1;
                  clearInterval(this.checkOrderTimer);
                }
                this.orderId = res.result.id;
                this.orderNo = res.result.no
                this.priceSum = Number(res.result.amount) + Number(res.result.serviceAmount);
              }
            })
          },
          loopCheckOrder(id) {
            this.checkOrderTimer = setInterval(()=>{
              this.checkPayStatus(id);
            }, 5000);
          },
          qrcode(url) {
            new QRCode('wxQrcode', {
              width: 500,
              height: 500,
              text: url,
              colorDark : "#000",
              colorLight : "#fff"
            })
          }
        }
    }
</script>

<style scoped lang="scss">

/deep/ .pay_div .ivu-table th{
      background-color: #00a65a!important;;
}
  .pay_div {
    display: flex;
    height: 60px;
    width: 180px;
    border: 1px solid rgb(205, 240, 243);
    margin-left: 20px;
    font-size: 20px;
    align-items: center;
    cursor: pointer;
    &.active {
      border: 1px solid #fc7c40;
    }
  }
/deep/ .ivu-table td{
  border-bottom: 1px solid #F2F2F2!important;
 }
  .div_button {
    cursor: pointer;
    border-width: 1px;
    border-color: rgb(220, 220, 220);
    border-style: solid;
    border-radius: 4px;
    display: inline-block;
    width: 114px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .div_button_select {
    cursor: pointer;
    border-width: 1px;
    border-color: #FB7B3C;
    border-style: solid;
    border-radius: 4px;
    display: inline-block;
    width: 114px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #FB7B3C;
  }

  .button_style {
    font-size: 12px;
    margin-left: 10px;
    display: inline-block;
    border-radius: 2px;
    background-color: rgb(54, 194, 207);
    width: 75px;
    height: 40px;
    z-index: 51;
    line-height: 40px;
    text-align: center;
    color: white;
    cursor: pointer;
  }

  #button_style div:nth-child(1) {
    margin-left: 50px;
  }

  #button_style div:nth-child(2) {
    margin-left: 25px;
  }

  .service_list > div:hover {
    box-shadow: 0 6px 20px -6px rgba(0, 0, 0, .1)
  }

  #order > span {
    display: inline-block;
    margin-right: 20px;
    cursor: pointer;
  }

  .service_div {
    font-size: 14px;
    line-height: 50px;
    & span {
      display: inline-block;
      height: 50px;
      margin-right: 40px;

      cursor: pointer;
    }
  }

  .select_span {
    color: rgb(252, 117, 0);
    border-bottom: 2px solid rgb(252, 117, 0);
  }

  .dpyx {
    & span {
      margin: 0 5px;
    }
  }

  .left_desc {
    padding-left: 20px;
    padding-top: 5px;
  }

  .rmzl_content_real {
    padding: 20px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-wrap: wrap;
    align-items: center;

  }

  .liang_hang_sheng {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .rmzl_title_price .desc {
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;;
    overflow: hidden;
  }

  .rmzl_image {
    width: 150px;
    height: 180px;
    float: left;
  }

  .rmzl_title_price {
    width: 228px;
    height: 180px;
    line-height: 1;
    float: left;
    box-sizing: border-box;
    padding: 20px 15px;
  }

  .div_center {
    width: 100%;
  }

  .center {
    width: 1200px;
    margin: 0 auto;
  }
</style>
<style lang="scss">
.wxQrcodePay {
  img{
    width: 200px;
    margin: 0 auto;
  }
}
.div_center2 {
  width: 95%;
  margin-left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted rgb(218, 226, 229);
}


</style>
